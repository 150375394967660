.typebutton {
  width: 110px;
  height: 40px;
  border-radius: 50px;
}
.joinBtnText {
  font-size: "17px";
  font-weight: 500
}
.warnText {
  color: #f46a6a;
  margin-top: .5rem;
}