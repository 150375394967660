:root {
  --background-color:#F4F7FE!important;
  --text-color-dark: #000!important;
  --text-dark: #000!important;
  --text-light: #ffffff;
  --chart-text: #878e94;
  --text-color-inactive-links: #111827;
  --dark-green: #22696D;
  --btn-disabled: #7FA8AA;
  --green-color-light: #84a17d;
  --green-color-light-table: #84a17d;
  --green-color-bg-card: #488386;
  --green-color-bg-card-tbl: white;
  --green-color-bg-card-th: #488386;
  --bs-body-font-size: 1rem;
  --bs-nav-link-color: var(--dark-green);
  --bs-nav-link-hover-color: var(var(--green-color-light));
  --bs-btn-color: #fff;
  --bs-btn-bg: #22696D;
  --bs-btn-border-color: #22696D;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #488386;
  --bs-btn-hover-border-color: #488386;
  --bs-btn-focus-shadow-rgb: 95, 106, 187;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #22696D;
  --bs-btn-active-border-color: #22696D;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2e4338;
  --bs-btn-disabled-border-color: #2e4338;
  --bs-card-green-light: #cddbc9;
  --bs-card-green-light-tr: #cddbc9;
}

* {
  box-sizing: border-box;
}
@font-face {
  font-family: 'Satoshi';
  src: url(../satoshi/Satoshi-Variable.ttf) format('truetype') ,
}

body {
  /* background: var(--background-color); */
  font-family: "DM Sans", sans-serif;
}

#root,
.vertical-menu,
.navbar-brand-box {
  background-color: #fff;
}
#page-topbar {
  background: transparent;
}
#root {
  background: none;
}
#page-topbar {
  box-shadow: none;
}
.menu-item-16px {
  font-size: 16px;
  color: var(--text-color-dark);
  font-weight: 500;
}
.color-dark-green{
  color: var(--text-color-dark);
}
.vertical-menu {
  /* width: 300px; */
  padding: 20px;
  /* width: 35%; */
  width: 335px;
  max-width: 100%;
  border-right: 2px solid var(--dark-greengreen-color-bg-card);
  box-shadow: none;
}
.row-flex-box {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
}
.main-content {
  /* flex-basis: 65%; */
  margin-left: 0px;
  margin-left: 370px;
  width: calc(100% - 340px);
}
.vertical-collpsed .main-content {
  width: calc(100% - 70px);
}
.parent-container {
  position: relative;
}

.orgImage{
  height: 2rem;
  width: 2rem;
}

.logoText{
  font-size: 14px;
}

.multiOrgImage{
  height: 2rem;
  width: 2rem;
}

.orgModalBody{
  background-color: var(--dark-green);
  margin: 10px;
  border-radius:10px ;
}

.lookUpBody{
  margin-top: 2rem;
  text-align: center;
}
.lookUpSearch{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;
  padding: 0 1rem;
  border-radius:1rem ;
  height: 6rem;
  background-color: white;
}
.CloudFlareAlert{
  background-color: var(--dark-green);
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
}
.settingWarning{
  color: red;
  background-color: var(--dark-greengreen-color-bg-card);
  border-radius: 0.3rem;
  margin: 0.5rem 0;
  padding: 0.2rem 0;
  border: 0.3rem solid var(--dark-green);
}

.lookUpSearch input:focus {
  box-shadow: 0 0 7px var(--bs-nav-link-hover-color);
}

.linkSection{
  border-radius: 0.3rem;
}
.acoountWaring{
  background-color: #FAE9E7;
  border-radius: 0.3rem;
}
.linkedAccBody, .nonLinkedAccBody{
  width: 10rem;
  height: 10rem;
  background-color: white;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.nonLinkedAccBody{
  flex-direction: column;
}
.rounded-icon {
  background-color: #3498db;
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.rounded-icon i {
  color: white;
}

.linkedAccBody img{
  width: 100%;
}

.acoountWaring p{
  color: #D1635F;
}

.lookUpResults{
  margin-top: 2rem;

}
.searchElement{
  border: 1px solid var(--dark-green);
  color: var(--dark-green);
  cursor: pointer;
  border-radius: 0.5rem;
} 
.searchElement .orgTitle{
  font-size: 1.4rem;
}
.searchElement:hover{
  color: var(--bs-nav-link-hover-color);
}
.lookUpSearch input{
  background-color: var(--background-color);
}

.paymentFailedSection{
  height: 100vh;
  width : 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.paymentFailedSection p{
  font-size: 3rem;
  font-weight: bold;
}


.lookUpHead{
  background-color: #154042;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.lookUpHead img{
  border-radius: 50%;
}
.lookUpHeader{
  background-color: var(--dark-green);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}
.lookUpHeader img{
  border-radius: 50%;
}

.payment-form {
  max-width: 500px;
  margin: 0 auto;;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.card-element {
  margin-bottom: 20px;
  padding: 20px;
  border: 1px solid #000000;
  border-radius: 5px;
}
.warning-section{
  position: relative;
  height: 0px;
  width: 99%;
  overflow: hidden;
  margin: 0;
  visibility: hidden;
  transition: visibility 0.5s ease-in-out,height 0.5s ease-in-out,margin 0.5s ease-in-out;
}

.warning-section.open{
  visibility: visible;
  height: 130px;
  margin: 2rem 0 0.5rem 0;
  }
  .warning-message{
    position: relative;
    height: 0px;
    width: 99%;
    overflow: hidden;
    border-radius: 0.5rem;
    margin: 0;
    visibility: hidden;
    transition: visibility 0.5s ease-in-out,height 0.5s ease-in-out,margin 0.5s ease-in-out;
  }
  
  .warning-message.open{
    visibility: visible;
    height: 50px;
    margin: 2rem 0;
    }

.popup-container {
  position: absolute;
  opacity: 0;
  border-radius: 1rem;
  right: -5000px;
  width: 99%;
  visibility: hidden;
  transition: right 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out;
}

.popup-container.open {
  width: 100%;
  opacity: 1;
  right: 0;
  padding: 0 2px;
  visibility: visible;
}
.popup-details{
  visibility: hidden;
}
.popup-details.open{
  visibility: visible;
}

.card-data-section{
  position: relative;
  width: 100%; 
  height:500px;
  overflow: hidden;
  background-color: var(--background-color);

}

.card-data-left {
  position: absolute;
  top: 0;
  left: -8rem;
  height: 120%;
  background-color: var(--dark-green);
  transform: skewX(-30deg) scale(3);
  z-index: 2;
}
.card-data-title{
  text-align: center;
}
.card-data-right {
  position: absolute;
  top: 0;
  left: 30%;
  height: 100%;
  z-index: 1;
}

.card-chip img{
  border-radius: 1rem;
}

.card-digit{
  letter-spacing: 2px;
  word-spacing: 1rem;
}

.credit-card{
  position: absolute;
  width: 35%;
  border-radius: 1rem;
  height: 50%;
  background-color: var(--bs-btn-hover-bg);
  top: 30%;
  left: -50px;
  z-index: 3;

}

.popupSection {
  background-color: #fff;
  width: 100%;
  height: 100%;
  box-shadow: 0 2px 5px rgba(213, 14, 14, 0.2);

}
.searchComponent{
  display: flex;
  align-items: center;
  border-radius: 8px;
}
.searchIcon{
  font-size: 1.5rem;
}
.counsellorDeleteIcon{
  border: 2px solid var(--bs-btn-bg);
  border-radius: 8px;
  padding: 0.4rem 0.4rem;
  margin: 0 1rem;
  color: #000;
}

.counsellorDeleteIcon:hover{
  color:white!important;
  background:var(--bs-btn-bg)
}
.counsellorDetailsBtn{
  border-radius: 8px;
}
.recordStatus{
  position: absolute;
  left: 1rem;
  top: 1rem;
}
.recordStatus img{
  border-radius: 5px;
}
.tooltip-info {
  position: absolute;
  bottom: 0px;
  left: -230px;
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
  padding: 8px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}
.moduleListStyle {
  flex-basis: 20%;
  margin: 10px 0;
  text-transform: capitalize;
}

.mainLoader{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.defaultText {
  color: var(--text-dark);
}
.defaultTextWhite {
  color: var(--text-light);
}

.slide-in {
  overflow: hidden;
  max-height: 0;
  transition: max-height 2s ease-out;
}

.slide-in.active {
  max-height: 1000px;
}

.remarkLoader{
  max-height: 45vh;
  min-height: 40vh;
}

.badFirBtn {
  background-color: var(--bs-btn-hover-border-color);
  padding: 8px 10px;
  border-radius: 8px;
  width: 220px;
}
.orgDropzone {
  border: 2px dashed #ccc3c3;
  border-radius: 8px;
  background-color: white;
}

.domainBtn{
  width: 150px;  
  margin-left: 20px;
}

.domainElements{
  height: 0.3px;
  background-color: gray;
  margin-bottom: 1rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* MAIN STYLES ENDED */

/* SIDEBAR CONTENT STARTED*/
#sidebar-menu {
  margin-top: 25px;
  font-family: "DM Sans", sans-serif;
}
#sidebar-menu ul li {
  margin-bottom: 16px;
}
#sidebar-menu ul li a {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #A3AED0;
  /* padding: 18px 30px; */
  border-radius: 8px;
  font-weight: 400;
  /* box-shadow: 0px 4px 4px 0px rgba(132, 161, 125, 0.1); */
}
#side-menu li a {
  display: flex;
  align-items: center;
}

.sub-menu-list-dashboard {
  margin-top: 15px;
}
.vertical-collpsed .sub-menu-list-dashboard {
  margin-top: 0px;
  width: 100%;
  border-radius: 0px 8px 8px 0px;
}
.sub-menu-list-dashboard li a {
  margin: 0px 10px !important;
  font-size: 16px !important;
  color: var(--text-color-inactive-links);
  padding: 5px 30px;
  margin-top: 5px;
  border-radius: 8px;
}
.vertical-collpsed .sub-menu-list-dashboard li a {
  border-radius: 0px !important;
  margin-left: 0px !important;
}

#sidebar-menu ul li a i {
  font-size: 16px !important;
  margin-right: 10px;
  color: #A3AED0!important;
}
.sub-menu-list-dashboard li a.active,
.sub-menu-list-dashboard li a.active i,
.mm-active .active {
  /* background: var(--dark-green) !important; */
  color: black !important;
}
.mm-active > a {
  color: #000 !important;
}
#sidebar-menu .mm-active > a > i {
  color: #000 !important;

}
.menu-title {
  font-size: 20px;
  font-weight: bold;
  color: var(--text-color-dark);
  text-transform: capitalize;
  margin-bottom: 30px;
}
.mm-active .active,
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a {
  color: black !important;
  position: relative;
}
.mm-active .active::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  width: 3px;
  height: 80%;
  border-radius: 8px;
  background: var(--dark-green);
}

.mm-active,
.mm-active > i {
  color: var(--text-color-inactive-links) !important;
}
.font-size-formatter h1,
.font-size-formatter h2,
.font-size-formatter h3,
.font-size-formatter h4,
.font-size-formatter h5,
.font-size-formatter h6 {
  font-size: 16px !important;
}
.mm-active .active i,
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a i,
.noti-icon i {
  color: black !important;
}
.vertical-collpsed .vertical-menu,
.vertical-collpsed .vertical-menu #sidebar-menu > ul > li:hover > a span {
  padding: 0px;
}

/* header navigation bar */
body.vertical-collpsed #page-topbar {
  margin-left: 70px;
  left: 0;
}
#page-topbar {
  position: relative;
  margin-left: 370px;
  padding: 0px 10px;
  left: 0;
}
.navbar-header {
  margin-top: 50px;
}
.topbar-goodmorning-admin h2,
h3.topbar-goodmorning-admin {
  color: var(--text-color-dark);
  font-weight: bold;
}
.topbar-goodmorning-admin p {
  color: var(--text-color-dark);
  font-weight: medium;
  font-size: 18px;
  font-weight: 500;
}

.green-background-notification {
  background: var(--green-color-light);
  font-size: 20px !important;
  padding: 8px 10px;
  color: white;
  border-radius: 100%;
  height: 20px;
  width: 20px;
}
.d-inline-block {
  margin-left: 20px;
}
.noti-icon .badge {
  background: var(--dark-green);
}
.dropdown-menu.show {
  border-radius: 8px;
  overflow: hidden;
}
#page-header-user-dropdown {
  background: white;
  border-radius: 8px;
  border: 1px solid transparent;
  box-shadow: 0px 4px 4px 0px rgba(132, 161, 125, 0.1) !important;
  padding: 10px 10px;
  color: var(--text-color-dark);
  font-weight: 700 !important;
  font-size: 18px !important;
  font-family: "DM Sans", sans-serif;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#page-header-user-dropdown span {
  font-size: 16px !important;
}

/* dashboard - main content */
.card-dark-bg {
  border-radius: 8px;
  background: var(--dark-green) !important;
  color: white;
  box-shadow: none;
}
.appointments-card-title-bg {
  border-radius: 0px !important;
}
#session-title,
.sessions-details-counsellors-invoice p {
  color: black !important;
  font-size: 24px !important;
  font-weight: 600;
  font-family: "DM Sans", sans-serif!important;
}
.basic-card-title-feedback {
  font-size: 22px !important;
}
.f-18-card-title-main {
  color: var(--text-color-dark) !important;
  font-size: 18px !important;
  font-weight: 700 !important;
 font-family: "DM Sans", sans-serif!important;
}

.session-flex-title-per {
  display: flex;
  align-items: center;
  color:white;
}
.percentage-session-stats {
  padding: 5px;
  margin-left: 10px;
  color: var(--text-color-dark);
  font-size: 16px;
  border-radius: 8px;
  background: var(--dark-greengreen-color-bg-card);
  font-family: "DM Sans", sans-serif;
}
.value-session-stats {
  font-size: 20px;
  font-family: "DM Sans", sans-serif;
  font-weight: 400;
  color:black;
}
.img-refresh-stats {
  position: absolute;
  color: white;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(278deg)
    brightness(200%) contrast(103%);
}

/* appointments chart */
.light-green-bg-card {
  background-color: white;
  box-shadow: none;
  border-radius: 16px !important;
  color: white;
}
.light-green-bg-card-video {
  background-color: var(--green-color-bg-card);
  box-shadow: none;
}
.light-green-bg-card-details{
  background-color: var(--green-color-bg-card);
  box-shadow: none;
}
.light-green-bg-card-table {
  background-color: #fff;
  box-shadow: none;
  border-radius: 16px !important;
}
.light-green-bg-card-white {
  background-color: #ffffff;
  box-shadow: none;
  border-radius: 8px !important;
}
.image-file-upload-user{
  max-width: 450px;
  width: 100%;
  border-radius: 8px;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.05);
}
.border-light-shadow{
  border: 1px solid #E8E8E8;
  box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.05);
}
.light-green-bg-card-white {
  background-color: #ffffff;
  box-shadow: none;
  border-radius: 8px !important;
}

.clientProfileCard{
  min-height: 140px;
}

.noteOptionsContainer {
  position: relative;
}

.noteOptions{
  position: absolute;
  width: 200px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px 0px rgba(132, 161, 125, 0.1) !important;
  border-radius: 8px !important;
  padding: 5px;
  top: 1rem;
  right: 1rem;
  z-index: 100;
}

.noteOptions p:hover {
  background-color: #eeeeee;
}

.light-green-bg-card-details-table{
  background-color: var(--green-color-bg-card-tbl);
  box-shadow: none;
}
.card-white {
  background-color: #fff;
  border-radius: 8px !important;
}
.dark-green-bg-card {
  background-color: var(--dark-green);
  border-radius: 16px;
  
  color: white;
}
.card-green-light {
  background: #fff !important;
  border: 1px solid #E8E8E8;
  box-shadow: 0px 18px 40px rgba(112, 144, 176, 0.05);
  border-radius: 8px;
  color: var(--text-color-inactive-links);
}

.ul-bg-dark {
  margin-left: 10px;
}
.light-green-bg-card .card-title,
.ul-bg-dark,
.basic-card-title,
.card-title-main,
.fc .fc-toolbar-title {
  color: var(--text-color-dark);
  font-size: 20px !important;
  font-weight: 700;
}
.fc .fc-toolbar-title {
  font-size: 18px !important;
  margin: 0px 10px;
}
.font-size-16.card-title {
  font-size: 18px !important;
}
.card-title-appointments {
  color: var(--text-color-dark);
  font-size: 18px !important;
  font-weight: 600;
}
.basic-card-title {
  margin: 30px 0px 15px 0px !important;
  /* margin-top: 75px; */
}
.light-green-bg-card span img {
  margin-left: 10px;
}

.custom-date-picker-style .css-1uccc91-singleValue {
  color: var(--text-color-inactive-links) !important;
}

.custom-date-picker-style input,
.custom-date-picker-style .dropdown {
  color: var(--text-color-inactive-links) !important;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  border-radius: 8px;
}

.seeMoreTimeSlots {
  background-color: transparent;
  border: none;
  font-size: 16px;
  color: #153c27;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
  outline: none;
  width: fit-content;
  border-radius: 2px;
  padding: 2px 5px;
}

.seeMoreTimeSlots:hover {
  background-color: #b5bbb8; /* Lighter color for hover */
  color: #153c27; /* Text color remains the same */
}

.custom-date-picker-style-bradius {
  color: var(--text-color-inactive-links) !important;
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  border-radius: 8px !important;
}

.css-1uccc91-singleValue {
  color: var(
    --text-dark
  ) !important; /* Set the text color of the selected option */
  font-weight: 500;
  font-family: "DM Sans", sans-serif;
  border-radius: 8px !important;
}

.css-12jo7m5 {
  border-radius: 2px;
  color: var(
    --text-dark
  ) !important; /* Set the text color of the selected option */
  font-size: 85%;
  overflow: hidden;
  padding: 3px;
  padding-left: 6px;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}
.default-search-input {
  color: #000 !important;
  font-family: "DM Sans", sans-serif;
  border-radius: 8px;
  font-size: 16px;
}
.default-search-input div{
  border-radius: 8px!important;
}
.default-search-input::placeholder {
  color: var(--text-color-dark) !important;
  opacity: 0.8;
}
.icon-default-light-bg-primary-green{
  padding:6px 8px;
  background: var(--background-color);
  color: var(--dark-green);
  border-radius: 8px;
}
.dropdownInputWhite {
  background: white !important;
  border: 1px solid #ced4da;
  border-radius: 8px;
  height: 41px;
  display: inline-block;
}

.client-details-card p {
  color: var(--text-color-dark);
  font-size: 22px;
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
}
.client-details-card p span {
  font-size: 16px;
}
.client-details-card-flex {
  padding-bottom: 10px;
  border-bottom: 2px solid var(--green-color-light);
}
.button-client-cards {
  background: var(--dark-green);
  margin-top: 10px;
  font-size: 20px;

  font-family: "DM Sans", sans-serif;
  font-weight: 500;
  border: none;
}
.button-client-cards:hover {
  background: var(--green-color-light);
  border: none;
}
.client-card-description {
  color: var(--text-color-dark) !important;
  font-size: 16px !important;
  font-weight: 500 !important;
 font-family: "DM Sans", sans-serif!important;
  margin-bottom: 20px !important;
  margin-top: 10px !important;
}

/* table */
.table-custom-redesign .table {
 font-family: "DM Sans", sans-serif!important;
}
.table-custom-redesign .table tbody tr {
  margin: 10px 0px;
  display: table-row;
}
.table-redesigned-topuser {
  background: var(--bs-card-green-light-tr);
}
.table-custom-redesign .table thead {
  border-bottom: 2px solid #E9EDF7;
}
.table-custom-redesign .table {
  border-radius: 8px;
  overflow: hidden;
}
.table-custom-redesign .table tbody tr {
  background: #fff;
}
.table-custom-redesign .table tbody td,
.table-custom-redesign .table th,
.no-show li {
 font-family: "DM Sans", sans-serif!important;
  color: var(--text-color-inactive-links) ;
  /* font-weight: bold !important; */
  background: transparent;
}
.table-custom-redesign .table th{
  color: #A3AED0;
}
.table-custom-redesign .table tbody td,
.no-show li {
  font-weight: 500 !important;
}
.no-show li {
  margin: 10px 0px;
  box-shadow: none;
  background: var(--bs-card-green-light);
  border-radius: 8px;
  padding: 5px;
}

.table-custom-redesign .table th {
  background: #fff;
}

/* APPOINTMENTS TABLE */
.card-para-main {
 font-family: "DM Sans", sans-serif!important;
  font-size: 18px !important;
  font-weight: 500 !important;
  color: var(--text-color-dark) !important;
  opacity: 1 !important;
}
.card-clock-appointments {
 font-family: "DM Sans", sans-serif!important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: var(--text-color-dark) !important;
}
.card-sub-title-tabs {
 font-family: "DM Sans", sans-serif!important;
  font-size: 16px !important;
  font-weight: 600 !important;
  color: var(--text-color-dark) !important;
}
.custom-date-picker-style-appointments {
  min-width: 200px;
}
.custom-date-picker-style-appointments button {
  font-size: 16px;
  font-family: "DM Sans", sans-serif;
  font-weight: 500 !important;
  color: var(--text-color-dark) !important;
  border: 1px solid #ccc;
  border-radius: 8px !important;
  min-height: 41px;
}
.mg-top-default-ui {
  margin-top: 50px;
}
.border-top-appointments-ui {
  border-top: 3px solid var(--green-color-bg-card);
  padding-top: 40px;
}
.bradius-none {
  border-radius: 0px;
}
.row.react-bootstrap-table-pagination {
  margin-top: 30px;
  margin-bottom: 15px;
}
.row.react-bootstrap-table-pagination span {
  color: var(--text-color-dark);
  font-weight: 500;
}
.row.react-bootstrap-table-pagination span button {
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px;
  color: var(--text-color-dark);
  font-weight: 500;
  margin-right: 10px;
}
.pagination.react-bootstrap-table-page-btns-ul {
  justify-content: flex-end;
}
.page-item .page-link {
  background: var(--background-color);
  color: var(--dark-green);
  margin: 0px 10px;
  font-weight: 500;
  min-width: 35px;
  z-index: 1;
}
.page-link {
  border-color: var(--dark-green);
}
.page-link:focus {
  border: 2px solid var(--dark-green);
  box-shadow: none;
}
.page-link:hover {
  border-color: var(--dark-green);
  background: var(--bs-btn-hover-bg);
  color: white;
}


.dot-appointments-card {
  background-color: var(--dark-green) !important;
}
.line-appointments-card {
  border-color: var(--dark-green) !important;
  height: 90% !important;
}
.bg-info {
  background: var(--dark-green) !important;
}
.page-link.active,
.active > .page-link {
  color: white;
  background: var(--dark-green);
  border: 1px solid var(--dark-green);
}
.green-accordion{
  background:#E9F0F0;
  border:none!important;
  border-radius:8px!important;

}
.green-accordion .accordion-button{
  background:#A4C2C3;
}
.green-accordion .accordion-button.collapsed{
  background:#E9F0F0;
}
.green-accordion .accordion-item{
  border:none!important;
  border-radius:8px!important;
}
.btn-primary {
  background: var(--dark-green);
  border: 1px solid var(--dark-green)!important;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  border: 0px;
  color: white!important;
}
.button-delete{
  background:#D32525;
  border-radius: 8px!important;
  font-weight: 400;
  font-size: 16px;
  border: 0px;
  color: white!important;
}
.button-delete:hover{
  background:#b62121;
}
.btn-group-counsellors{
  width: 100%;
}
.btn-group-counsellors button{
  width: fit-content;
}
.btn-light{
  background-color: var(--background-color);
  border: 1px solid var(--dark-green)!important;
  border-radius: 8px!important;
  font-weight: 400;
  font-size: 16px;
  color: var(--dark-green)!important;
}
.dropdown-item a{
  color: #000;
}
.btn-Good-fit{
  background: white;
  border: none;
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  border: 0px;
  color:#25D36B;
  border: 1px solid #25D36B;
}
.btn-Good-fit:hover{
  background: #25D36B;
  color: white;
}
.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background: var(--bs-btn-hover-bg);
  border: 0px;
  outline: var(--bs-btn-hover-bg) solid 1px !important;
}
.btn-primary:hover {
  background: var(--bs-btn-hover-bg);
  border: 0px;
}
.link {
  color: var(--dark-green);
  cursor: pointer;
}

.form-check-input:checked {
  background-color: var(--dark-green);
  border-color: var(--dark-green);
}
.edit-btn.btn-primary {
  
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-radius: 8px;
}
.edit-btn  img {
  width: 18px;
}
.btn-primary:hover {
  text-decoration: none !important;
}
.tab-items-counsellor-details li {
  color: var(--text-color-inactive-links);
}
.tab-items-counsellor-details li.active {
  border-bottom: 2px solid var(--dark-green);
  color: var(--text-dark);
}
.transparent-bg-card {
  background: transparent;
  box-shadow: none;
  border: none !important;
}

.remark_container {
  background-color: white;
  margin-bottom: 1rem;
}


.card-fixed-appointment-list {
  overflow-y: scroll;
  height: 650px;
  padding-right: 8px;
  margin-top: 30px;
}
.card-fixed-appointment-list::-webkit-scrollbar {
  width: 4px;
}
.card-fixed-appointment-list::-webkit-scrollbar-track {
  background: #f3f7f2;
  border-radius: 8px;
}

.card-fixed-appointment-list::-webkit-scrollbar-thumb {
  background-color: var(--dark-green);
  border-radius: 8px;
}
.form-custom-white-inputs label {
  color: var(--text-dark);
  margin-bottom: 5px;
  font-weight: 600;
}
.form-custom-white-inputs div,.form-custom-white-inputs input,.form-control,.default-redesign-input div{
  border-radius:8px!important;
}
.form-control{
  border:1px solid #E0E5F2;
}
.default-redesign-input div{
  border:0px;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-space-around {
  justify-content: space-around;
}
.phoneinpute input{
  border-radius:8px !important;
}
.selected-flag,.react-tel-input .flag-dropdown{
  border-radius: 8px 0px 0px 8px !important;
}
.default-redesign-input,

.react-datepicker__input-container input,
.form-custom-white-inputs input[type="text"],
.form-custom-white-inputs input[type="number"],
.form-custom-white-inputs input[type="email"],
.form-custom-white-inputs input:disabled,
.form-custom-white-inputs input[type="tel"],
.form-custom-white-inputs textarea,
.default-input-style {
  background: white;
  font-size: 16px;
  color: #000000 !important;
  border: 1px solid #E0E5F2;
  border-radius: 8px;
}
.additional-information-client {
  min-height: 150px !important;
}
.appointment-list-toggle-button.btn-primary span,
.appointment-list-toggle-button.btn-primary i {
  color: white !important;
}
.form-custom-white-inputs input:disabled,
.form-custom-white-inputs input[type="text"]:disabled {
  background-color: #f2f2f2;
}
.DraftEditor-editorContainer {
  padding: 10px;
  font-weight: 500;
}
.rdw-editor-main,
.form-custom-white-inputs input[type="text"] {
  background: white;
  border: 1px solid #ccc;
  border-radius: 8px ;
}
.rdw-editor-main,.rdw-dropdownoption-default{
  color:rgb(73, 80, 87)!important;
}
.btn:disabled,
.btn.disabled,
.btn-disabled,
fieldset:disabled .btn {
  background: var(--btn-disabled) !important;
  color: white;
  border: none;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
}
.btn-disabled:hover {
  color: white !important;
}
.btn-inactive,
.btn-cancel-light-green-bg {
  background: white;
  color: var(--dark-green);
  border: 2px solid var(--dark-green);
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  box-shadow: none !important;
 font-family: "DM Sans", sans-serif!important;
}
.btn-cancel-light-green-bg {
  background: var(--bs-card-green-light);
  color: var(--dark-green);
  border: none;
  border: 2px solid var(--bs-card-green-light);
}

.btn-inactive:hover,
.fc .fc-button-primary:disabled,
.btn-cancel-light-green-bg:hover {
  background: var(--btn-disabled);
  color: white !important;
  border: 2px solid transparent;
  font-family: "DM Sans", sans-serif;
  border-radius: 8px;
}
.btn-cancel-light-green-bg:hover i {
  color: white !important;
}
.btn-inactive:hover i {
  color: white !important;
}
.btn-underline-green-dark {
  color: var(--dark-green);
  text-decoration: underline !important;
  font-weight: 600;
}

/* card invoice */
.fc .fc-button-primary {
  background-color: var(--dark-green);
  border: var(--dark-green);
}
.card-title-invoice{
  font-weight: 600;
}
.card-title-invoice,.card-sub-title-invoice {
  font-size: 18px;
  color: var(--text-color-dark);
}
.card-sub-title-invoice{
  font-weight: 400;
}
.card-title-availablity {
  font-weight: 500 !important;
}
/* .card-sub-title-invoice {
  font-size: 18px !important;
  color: var(--text-color-inactive-links);
} */

/* icon */
.text-primary{
  color: var(--dark-green)!important;
}
.icon-double-color{
  color: var(--dark-green)!important;
  background: var(--background-color)!important;
  font-size: 18px;
  font-weight: bold;
  border-radius: 100%;
  padding:10px 15px!important;
}
.sessions-details-counsellors-invoice {
  padding: 20px 40px !important;
}
.availability-calendar .fc-daygrid-event-dot {
  display: none !important;
}
.availability-calendar .fc-direction-ltr .fc-daygrid-event .fc-event-time,
.availability-calendar .fc-direction-ltr .fc-daygrid-event.fc-event-end span,
.availability-calendar-timeslot {
  color: var(--dark-green);
  font-weight: 500;
}
.legend-list {
  font-size: 16px !important;
  font-weight: bold !important;
  color: var(--text-color-inactive-links);
  text-transform: capitalize !important;
}
.form-switch-lg .form-check-input {
  left: 0;
}
.user-group-details-table .form-check {
  margin: 8px 0px;
  color: var(--text-color-inactive-links);
  font-weight: 500;
}
.fc-theme-standard td,
.fc-theme-standard th {
  background: white;
  border: 1px solid #f3f7f2;
}
.fc .fc-scrollgrid-liquid {
  border-radius: 8px;
  overflow: hidden;
}
/* calendar */
.fc .fc-button-active {
  background: var(--dark-green) !important;
  border: 1px solid var(--dark-green) !important;
}
.fc {
  background: transparent !important;
 font-family: "DM Sans", sans-serif!important;
}
.fc-toolbar-chunk .fc-button-primary.fc-prev-button,
.fc-toolbar-chunk .fc-button-primary.fc-next-button {
  color: var(--dark-green) !important;
  border: none;
}
.fc-toolbar-chunk .fc-button-primary.fc-prev-button span,
.fc-toolbar-chunk .fc-button-primary.fc-next-button span {
  color: var(--dark-green) !important;
}
.fc .fc-button-primary:not(:disabled).fc-button-active,
.fc .fc-button-primary:not(:disabled):active {
  background: var(--bs-btn-hover-bg);
  color: white !important;
}
.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child),
.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  color: var(--dark-green);
  border: 1px solid var(--dark-green);
 font-family: "DM Sans", sans-serif!important;
}
.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 40px;
}
.fc-day-today .fc-daygrid-day-number {
  background-color: var(--dark-green) !important;
}
.fc-direction-ltr .fc-daygrid-more-link {
  color: var(--dark-green) !important;
  font-weight: 600;
}
.tabActive {
  background-color: var(--dark-green) !important;
}

.vertical-menu .vertical-menu-btn {
  top: 35px;
}
.vertical-menu .vertical-menu-btn:hover {
  background-color: transparent !important;
  border-color: transparent !important;
}
.logo-default img {
  width: 80%;
}
.logo-small-default img {
  width: 100%;
}
.hamburger-icon {
  /* display: none; */
  cursor: pointer;
}
.navbar-brand-box {
  background: transparent;
}
.search-label-appointments {
  width: 250px;
}
.fx-basis-appointment-dates .react-datepicker-wrapper {
  flex-basis: 100%;
}
.appointment-card-name {
  font-size: 24px !important;
  font-weight: 700 !important;
 font-family: "DM Sans", sans-serif!important;
}
.appointment-card-height {
  height: 100%;
  object-fit: cover;
}
.avatar-icon {
  background-color: var(--dark-green) !important;
}
.avatar-icon h5 {
  color: white !important;
}
.avatar-container-design {
  background-color: var(--green-color-bg-card) !important;
  border: 1px solid var(--dark-green) !important;
  box-shadow: none;
  border-radius: 8px !important;
  height: 100%;
  width: 100%;
  aspect-ratio: 4/3;
  overflow: hidden;

  .avatar {
    padding: 20px;
    border-radius: 50%;
  }
}


.mini-video {
  position: absolute;
  bottom: 2rem;
  right: 2rem;
  height: 150px !important;
  width: 200px !important;
  border: 4px solid rgb(102, 157, 246);
  border-radius: 1rem;
  background-color: grey;

  .video-stream {
    padding: 0;
   margin: 0;
    height: 100% !important;
    width: 100% !important;
  }
  .mic-status{
    top:5px;
    right:5px
  }
  .remote-participant,
  .local-participant {
    position: relative;
    padding: 0;
    width: 200px;
    height: fit-content;
  }
}

.video-room {
  transform: scaleX(-1);
  width: 100%;
  object-fit: cover;
}

.video-container,
.mic-status {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  width: 100%;
}
.avatarContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
  width: 100%;
}

.avatarContainer2 {
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.video-container2,
.mic-status2{
  display: flex;
  height: 85vh;
  width: 50%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.video-container3,
.mic-status3{
  display: flex;
  height: 85vh;
  width: 100%;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.video-container-lobby{
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
}

.mini-video .avatarContainer {
  width: 10rem;
  height: 10rem;

  .avatar {
    padding: 20px;
  }
}

.local-participant {
  position: relative;
  display: flex;  
  height: fit-content;
  justify-content: center;
  align-items: center;
}
.remote-participant {
  position: relative;
  flex-grow: 1; 
  flex-basis: 25%; 
  max-width: 50%;
}
.remote-participant2 {
  position: relative;
  flex-grow: 1; 
  flex-basis: 50%; 
  max-width: 50%;
  min-height: 50%;
  max-height: 50%;
  display: flex;
}
.remote-participant3 {
  position: relative;
  flex-grow: 1; 
  flex-basis: 25%; 
  max-width: 25%;
  min-height: 50%;
  max-height: 50%;
  display: flex;
  align-items: center;
}
.remote-participant4 {
  position: relative;
  flex-grow: 1; 
  flex-basis: 25%; 
  max-width: 20%;
  min-height: 25%;
  max-height: 25%;
  display: flex;
  align-items: center;
}

.remoteVideo{
  position: relative;
  height: 100%;
}

.remote-video {
  transform: scaleX(1);
  width: 100%;
  height: 100%;
  border-radius: 1rem;

}
.video-stream {
  transform: scaleX(-1);
  padding: 0.5rem;
  border-radius: 1rem;
}

.mic-status {
  position: absolute;
  right: 20px;
  top: 25px;
  height: 1.5rem;
  width: 1.5rem;
  background-color: white;
  border-radius: 50%;
}

/* #c-stream {
  border-radius: 0px !important;
} */
.invalid-feedback{
  text-transform:uppercase;
}
.Toastify__toast--info {
  background-color: var(--dark-green) !important;
  border-radius: 8px;
 font-family: "DM Sans", sans-serif!important;
}
.Toastify__toast--success {
  background-color: var(--dark-green) !important;
  border-radius: 8px;
  z-index: 1111;
 font-family: "DM Sans", sans-serif!important;
}
.width-fit-content {
  width: fit-content !important;
}
/* inline to file */
.errorBoundary-ml-10 {
  margin-left: 10px;
}
.candidate-multi-select-error {
  color: #f46a6a;
  font-size: 87.5%;
  margin-top: 0.5rem;
}
.delete-modal-i-style {
  font-size: 3em;
  color: orange;
}
.filters-mg-top-5 {
  margin-top: 5px !important;
}
.width-190 {
  width: 190px;
}
.height-38 {
  height: 38px;
}
.h38-w64-btn {
  height: 38px;
  width: 63px;
  border: none;
  border-radius: 8px;
}
.close-btn-icon-table {
  font-size: 24px;
  color: white;
  font-weight: 600;
}
.w-70 {
  width: 70px;
}
.day-button-availibility {
  width: 50px; /* Set the width and height to the desired size */
  height: 50px; /* Set the width and height to the desired size */
  padding: 17px 22px;
  font-size: 18px;
  font-weight: bold;
  border: 1px solid var(--dark-green);
  border-radius: 50%; /* Make the border-radius 50% to create a perfect circle */
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}
.h-40 {
  height: 40px;
}
.fx-10 {
  flex: 10%;
}
.h-900 {
  height: 900px;
}
/* ------------------------------------------ */
/* init form */
.init-form-topics-mgtop {
  margin: 1rem 0;
}
/* ------------------------------------------ */
/* twilio details */
.twilio-mgt-20 {
  margin-top: 20px;
}
/* ------------------------------------------ */
/* notification dropdown */
.bg-color-noti {
  background: #d9d9d9;
}
.color-button-appointment-type {
  display: inline-block;
  width: 35px;
  height: 35px;
}
.h325-p20 {
  height: 325px;
  padding: 20px;
}
.height-1 {
  height: 1px;
}
.simplebar-h230 {
  height: 230px;
}
/* ------------------------------------------ */
/* profile menu */
.pm-fsize-24px {
  font-size: 24px;
}
/* ------------------------------------------- */
/* LOTTIE ANIMATION0 */
.postition-relative-lottie {
  position: relative;
}
.lottie-avatar {
  top: 50%;
  left: 50%;
  background-color: #f4ebff;
  border-radius: 50%;
  padding: 4rem;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
/* ------------------------------------------- */
/* sidebar content */
.sidebar-mx-height-100 {
  max-height: 100%;
}
/* ------------------------------------------- */
/* support */
.support-h-100vh {
  height: 100vh;
}
/* -------------------------------------------- */
/* appointment types */
.bg-direc-button {
  color: white;
}
/* --------------------------------------------- */
/* ui tabs accordion */
.cursor-pointer-accordion {
  cursor: pointer;
}
/* --------------------------------------------- */
/* li horizontal timeline */
.li-horizontal-timeline-width {
  width: 279px;
}
.margin-left-10 {
  margin-left: 10px;
}
/* ---------------------------------------------- */
/* user role list */
/* ---------------------------------------------- */
/* user role list */
.mr-8-edit-image {
  margin-right: 8px;
}
.user-role-create-height {
  height: calc(100vh - 264px);
}
.user-role-create-height-double {
  height: calc(100vh - 420px);
}
/* ------------------------------------------------ */
/* details widget*/
.details-widget-h4-span {
  font-family: "DM Sans", sans-serif;
  font-weight: 700;
  font-size: 30px;
  color: var(--text-color-inactive-links);
}
/* ------------------------------------------------ */
/* user create*/
.form-feedback-display-block {
  display: block;
}
/* ------------------------------------------------ */
/* persmission component */
.pm-component-label-style {
  text-transform: capitalize;
  margin-bottom: 20px;
}
/* --------------------------------------------- */
/* ui toast */
.ui-toast-z-11 {
  z-index: 11;
}
/* --------------------------------------------- */
/* appointment types */
.appointment-type-w50-bradius {
  width: 50px;
  border-radius: 8px;
  height: 20px;
}
/* --------------------------------------------- */
/* appointment calendar */
.white-color-calendar {
  color: #fff;
}
/* --------------------------------------------- */
/* appointment list */
.mgbottom-appointment-list-50 {
  margin-bottom: 50px;
}
.btn-w-62-ml-10 {
  width: 63px;
  margin-left: 10px;
}
.btn-app-list-f-24 {
  font-size: 24px;
}

/* ---------------------------------------------- */
/* forget password */
.mt-13px-fp {
  margin-top: 13px;
}
/* ---------------------------------------------- */
/* wizard form */
.wizard-form-cursor-no-drop {
  cursor: no-drop;
}
/* ---------------------------------------------- */
/* invoice detail */
.invoice-detail-width-70 {
  width: 70px;
}
.invoice-detail-width-120 {
  width: 120px;
}
/* ---------------------------------------------- */
/* expanded invoice row */
.expanded-invoice-row-link {
  color: rgb(73, 80, 87);
}
/* ---------------------------------------------- */
/* blogs */
.blogs-mx-width-150 {
  max-width: 150px;
}
.blogs-img-styling {
  display: block;
  max-width: 80px;
  max-height: 80px;
  width: auto;
  height: auto;
  object-fit: cover;
}
/* ---------------------------------------------- */
/* client details */
.client-details-appointment-heading-mt-30 {
  margin-top: 30px;
}
/* ---------------------------------------------- */
/* google maps */
.gmaps-height-400 {
  height: 400px;
}
/* ---------------------------------------------- */
/* client manage */
.client-manage-mbottom125rem {
  margin-bottom: 1.25rem;
}
/* ---------------------------------------------- */
/* invoice-mgmt-preview*/
.padding6-20 {
  padding: 6px 20px;
}
/* ---------------------------------------------- */
/* custom dropdown*/
.custom-dropdown-btn-styling {
  background: hsl(0, 0%, 100%) !important;
  /* color: hsl(0, 0%, 20%) !important; */
  width: 100% !important;
  /* border-radius: 8px !important; */
}
.custom-dropdown-btn-styling[isselected="true"] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid var(--dark-green);
}
.custom-dropdown-p0p8 {
  padding: 0px 8px;
  text-overflow: ellipsis;
  width: 85%;
}
.custom-dropdown-blanket {
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  position: fixed;
  width: 100%;
  z-index: 1;
}
/* ---------------------------------------------- */
/* direct client list */
.direct-client-list-blanket-cursor-txt {
  cursor: text;
}
.direct-client-list-legend-margin {
  margin-right: 5px;
}
/* ---------------------------------------------- */
/* Appointment info */
.join-video-btn-color {
  color: white !important;
}
/* ---------------------------------------------- */
/* counselor details */
.chip-style-margin {
  margin: 0px 5px 5px 0px;
}
.d-grid-pl-5 {
  display: grid;
  grid-template-columns: 1fr;
  padding-left: 5px;
}
.phone-number-field-style {
  cursor: pointer;
  width: fit-content;
  font-weight: bold;
}
/* ---------------------------------------------- */
/* ui sweet alerts*/
.p3em-bradius20-bg {
  background-color: #ebebeb;
  padding: 3em;
  border-radius: 20px;
}
.p-absolute-t0-r0 {
  position: absolute;
  top: 0px;
  right: 0px;
}
/* ---------------------------------------------- */
/* ui progress bars*/
.ui-progress-bar-height3 {
  height: 3px;
}
.ui-progress-bar-height20 {
  height: 20px;
}
/* ---------------------------------------------- */
/* ui modal*/
.ui-modal-min-height {
  min-height: 1500px;
}
/* ---------------------------------------------- */
/* ui image cropper*/
.ui-image-cropper-style {
  height: 400px;
  width: 100%;
}
/* ---------------------------------------------- */
/* twilio video room */
.twilio-video-room-icon-style-fsize20 {
  font-size: 20px;
  font-weight: 600;
}
.font20 {
  font-size: 20px;
}
.font-weight-bold {
  font-weight: bold;
}
.twilio-video-room-pos-abs-left {
  position: absolute;
  left: -9999px;
}
.twilio-video-room-fsize-14-link {
  font-size: 14px;
  margin-left: 10px;
  font-weight: 600;
}
.twilio-video-room-margin-left20 {
  margin-left: 20px;
  -ms-word-break: break-all;
  word-break: break-all;
}
.twilio-video-room-meeting-ready-card {
  position: absolute;
  left: 1.25rem;
  bottom: 0;
  z-index: 9999;
  width: 300px;
  /* -ms-word-break: break-all;
  word-break: break-all; */
}
/* ---------------------------------------------- */
/* mini widget  */
.mini-widget-report-prefix {
  font-family: "DM Sans", sans-serif;
  font-size: 26px;
}
/* ---------------------------------------------- */
/* rating questions */
.star-rating-style {
  font-size: 32px;
  color: #fec84b;
}
.star-outline-style {
  font-size: 32px;
}
/* ---------------------------------------------- */
/* session type */
.session-type-mgtop-15 {
  margin-top: 15px;
}
.session-type-mgtop-40 {
  margin-top: 40px;
}
.session-type-mgtop-55 {
  margin-top: 55px;
}
/* ---------------------------------------------- */
/* form advanced */
.form-advanced-off-symbol {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 12px;
  color: white;
  padding-right: 2px;
}

/* ---------------------------------------------- */
/* pie chart */
.pie-chart-mgtop-30px {
  margin-top: 30px;
}
.bar-chart-height400px {
  height: 400px;
}

/* ---------------------------------------------- */
/* extra toolkit height 40px */
.height-40px-extra-toolkit {
  height: 40px;
}
.btn-close-extra-toolkit {
  background: var(--background-color);
  color: var(--dark-green);
  font-weight: 600;
  font-size: 24px;

  border: none;
  border-radius: 8px;
  padding: 0px 12px;
  border: 1px solid var(--dark-green);
}
.btn-close-extra-toolkit-icon {
  font-size: 24px;
  font-weight: 600;
}
/* ---------------------------------------------- */
/* payment type */
.payment-type-flex-70 {
  flex: 70%;
}
/* ---------------------------------------------- */
/* counselor location */
.counselor-location-fsize32 {
  font-size: 32px;
}
/* ---------------------------------------------- */
/* location */
.divider-w-h {
  width: 1px;
  height: auto;
}
/* ---------------------------------------------- */
/* least user */
.least-user-txt-style {
 font-family: "DM Sans", sans-serif!important;

  font-weight: 500 !important;
  color: var(--text-color-inactive-links) !important;
}
/* ---------------------------------------------- */
/* invoice list */
.invoice-list-fsize-14 {
  font-size: 14px;
}
.invoice-list-fsize-20 {
  font-size: 20px;
}
.prev-button-styling {
  font-size: 25px;
  position: fixed;
  top: 50%;
  z-index: 1111;
  background: var(--dark-greengreen-color-bg-card);
  border-radius: 50px;
  color: var(--dark-green);
}
.next-button-styling {
  font-size: 25px;
  position: fixed;
  top: 50%;
  z-index: 1111;
  background: var(--dark-greengreen-color-bg-card);
  border-radius: 50px;
  color: var(--dark-green);
  right: 0;
}
.labelfor-regular-hours-service {
  margin-bottom: 40px !important;
  font-size: 18px;
  display: block;
}
/* ---------------------------------------------- */
/* counsellor pay list */
.counsellor-pay-img-24 {
  height: 24px;
  width: 24px;
}
.min-width150-date-picket-cpl {
  min-width: 150px;
  margin-right: 10px;
}
.btn-bg-close-list {
  background: var(--bs-card-green-light);
  color: var(--dark-green);
  padding: 0px 10px;
  margin-left: 3px;
}
/* ---------------------------------------------- */
/* counselo appointment stats */
.card-height-calc-ap-stats {
  height: calc(100% - 60px);
}
/* ---------------------------------------------- */
/* counselo dashboard */
.counselor-dashboard-div-abs {
  width: calc(76%);
  height: 100%;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  overflow: hidden;
  z-index: 99;
}
.counselor-dashboard-div-fixed {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
.counselor-appointcards-width-100 {
  width: 100px;
}
.counselor-appointcards-marginright-10 {
  margin-right: 10px;
}
.btn-handleDateClick-mgleft-15 {
  margin-left: 15px;
}
.minwidth-110-cd {
  min-width: 110px;
}
/* ---------------------------------------------- */
/* date filter */
.date-filter-w100px {
  width: 100px;
}
/* timeslot */
/* ---------------------------------------------- */
.timeslot-weekday {
  padding: 20px;
  /* background: yellow; */
  border-radius: 8px;
  cursor: pointer;
}
.timeslot-weekday.active {
  border: 3px solid var(--dark-green) !important;
}
.per-25-input {
  width: fit-content !important;
}
.per-10-input {
  width: 75px !important;
}
/* media queries */


@media screen and (min-width:2200px){
  #sidebar-menu ul li a {
    font-size:18px;
  }
}
@media screen and (max-width: 1600px) {
  .vertical-menu {
    /* width: 300px; */
    padding: 20px;
    /* width: 35%; */
    /* width: 20%; */
    max-width: 100%;
    border-right: 2px solid var(--dark-greengreen-color-bg-card);
    box-shadow: none;
  }

  .main-content,
  #page-topbar {
    /* flex-basis: 65%; */
    margin-left: 340px;
  }
  .search-input-search-appointments {
    width: 65% !important;
  }
  .search-label-appointments {
    width: 100% !important;
  }
  .search-input-buttons-appointments {
    width: 35% !important;
  }
}
@media screen and (max-width: 1440px) {
  .blog-container-justify-center {
    justify-content: center;
  }
  #sidebar-menu ul li a {
    font-size: 16px;
  }

  .client-details-card p {
    font-size: 20px;
  }
  .value-session-stats {
    font-size: 20px;
  }
  #sidebar-menu ul li a {
    padding: 10px 15px;
  }
  .fc .fc-toolbar.fc-header-toolbar {
    padding: 0px;
  }
}
@media screen and (max-width: 1300px) {
  .vertical-collpsed .vertical-menu .simplebar-scrollbar,
  .vertical-collpsed .vertical-menu .vertical-menu-btn {
    /* display: inline-block !important; */
  }
  .vertical-menu .navbar-brand-box,
  .vertical-menu .vertical-menu-btn {
    display: inline-block !important;
  }
  .btn-appointment-list {
    margin-left: 10px;
  }
  .hamburger-icon {
    display: inline-block;
    width: 30px;
  }
  .logo-small-hidden-mobile {
    display: none;
  }
  .vertical-menu {
    width: 30%;
  }

  .main-content {
    width: 100%;
    margin-left: 0px;
  }

  .topbar-goodmorning-admin h2 {
    font-size: 24px;
  }
  .light-green-bg-card .card-title,
  .ul-bg-dark,
  .basic-card-title,
  .card-title-main,
  .fc .fc-toolbar-title,
  .client-details-card p,
  .button-client-cards {
    font-size: 18px !important;
  }
  .logo-default img {
    width: 50%;
  }

  .topbar-goodmorning-admin p {
    font-size: 16px;
  }
  #sidebar-menu {
    margin-top: 20px;
  }
  #sidebar-menu ul li ul.sub-menu li a {
    padding: 0.5rem 1rem;
  }
  .card-para-main {
    font-size: 16px !important;
  }
  .h1-login-details {
    font-size: 24px;
  }
  .h1-login-details {
    margin-bottom: 10px !important;
  }
  .firebaseui-subtitle,
  .firebaseui-title,
  .form-label-login,
  .form-label {
    font-size: 16px !important;
  }
  #page-topbar,
  body.vertical-collpsed #page-topbar {
    margin-left: 0px;
  }
  body.sidebar-enable .vertical-menu {
    top: 0px;
    padding: 40px;
  }
  .vertical-menu .navbar-brand-box {
    display: block;
  }

  .logo-default img {
    width: 20%;
    max-width: 150px;
  }

  #page-topbar .navbar-header .navbar-brand-box {
    display: inline-block;
    position: relative;
  }
  .logo span.logo-sm {
    display: inline-block;
  }
  .navbar-brand-box {
    width: 100%;
    padding: 10px;
  }
  .logo-small-default img {
    display: none;
  }
  .buttons-div-login-page button {
    /* flex-basis: 100%; */
    /* border-radius: 0px !important; */
    margin: 0px;
  }
  .card-fixed-appointment-list {
    height: 450px;
  }
}
@media screen and (max-width: 1200px) {
  .buttons-div-login-page button {
    flex-basis: 100%;
    border-radius: 0px !important;
    /* margin: 0px; */
  }
  .search-input-search-appointments {
    width: 80% !important;
  }
  .search-input-buttons-appointments {
    width: 20% !important;
  }
  .search-input-buttons-appointments .row {
    justify-content: flex-start;
    column-gap: 20px;
  }
}
@media screen and (max-width: 1000px) {
  .card-fixed-appointment-list {
    height: 250px;
  }
  body.sidebar-enable .vertical-menu .logo span.logo-lg {
    display: inline-block;
    position: absolute;
    width: 70%;
  }
  .navbar-brand-box {
    width: 50%;
  }
  .logo-default img {
    width: 100%;
  }

  #sidebar-menu {
    margin-top: 50px;
  }
}
@media screen and (max-width: 990px) {
  .lookUpSearch{
    margin-top: 1rem;
  }
  .search-input-search-appointments {
    width: 70% !important;
  }
  .search-input-buttons-appointments {
    width: 30% !important;
  }

  .vertical-menu {
    width: 45%;
  }
  .vertical-collpsed .main-content {
    width: 100%;
  }
  .card-data-section{
    height: 500px;
  }
  
  .card-data-left {
    height: 0%;
  }

  .card-data-right {
    position: absolute;
    top: 0;
    left: 0%;
    height: 70%;
    background-color: var(--background-color);
    z-index: 1;
  }
  .credit-card{
    display: none;
  }
}
@media screen and (max-width: 780px) {

  .search-input-search-appointments {
    width: 50% !important;
  }
  .search-input-buttons-appointments {
    width: 50% !important;
  }
  .card-data-section{
    height: 500px;
  }
  
  .card-data-left {
    height: 0%;
  }

  .card-data-right {
    position: absolute;
    top: 0;
    left: 0%;
    height: 70%;
    background-color: var(--background-color);
    z-index: 1;
  }
  .credit-card{
    display: none;
  }
}
@media screen and (max-width: 650px) {
  .lookUpSearch{
    margin-top: 1rem;
  }
  .vertical-menu {
    width: 100%;
  }
  .counselor-dashboard-div-abs {
    width: 100%;
  }
  .page-content {
    padding-top: 60px;
  }
  .navbar-header {
    flex-wrap: wrap;
    height: 100%;
  }
  .profile-menu-right-mobile {
    justify-content: end;
    width: 100%;
  }
  .close-button {
  position: absolute;
  right: 0px;
  top: 0;
  }
  .warning-section.open{
    visibility: visible;
    min-height: 250px;
    margin: 1rem 0;
  }
}
@media screen and (max-width: 499px) {
  .icon-double-color{
    font-size:16px;
    padding:8px 14px;

  }
  .lookUpSearch{
    margin-top: 1rem;
  }
  .fc-header-toolbar {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    row-gap: 10px;
  }
  .appointment-list-toggle-button,
  .appointment-list-button {
    margin-top: 20px;
  }
  .search-input-search-appointments {
    width: 100% !important;
  }
  .flex-direction-column-mobile {
    flex-direction: column;
    gap: 10px;
  }
  .min-width150-date-picket-cpl {
    margin-right: 0px;
  }
  .flex-wrap-mobile-only {
    flex-wrap: wrap;
  }
  .mg-x-sm {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .justify-content-center-mobile-only {
    justify-content: start !important;
  }
  .buttons-appointment-list-div .row {
    justify-content: center;
  }
  .width-100-mobile-devices {
    width: 100%;
  }
  .margin-top-10px-xs {
    margin-top: 10px;
  }
  .margin-zero-small {
    margin: 0px;
  }
}
.pointer {
  cursor: pointer !important;
}
