.cardcenter {
  justify-content: center;
}
.addauct {
  text-align: right;
}
.circleinfo {
  margin-left: 3px;
  font-size: 15px;
  color: #cac5c5;
}
.subTitleStyle {
  font-size: 12px;
  color: #74788d8a;
}
.subTitleStyle a {
  text-decoration: underline !important;
}
.copyIcon {
  cursor: pointer;
}
.subtitlemenu {
  font-family: var(--bs-body-font-family);
  font-size: 14px !important;
  color: #000 !important;
  opacity: 0.5;
}
.userProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.socialLinks {
  display: flex !important;
  margin-top: 2.875rem;
  cursor: pointer;
}
.socialImg {
  height: 2.313rem;
  width: 2.313rem;
  border-radius: 50%;
  padding: 2px;
  background-color: #fff;
  margin-right: 1.5rem;
}

.card {
  height: 90%;
}
.headingsubtitle {
  margin-left: 12px;
  margin-top: 7px;
}
.titleInvoice {
  color: #1d2939;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 10px;
}
.legend {
  color: #1d2939;
  font-weight: 600;
  font-size: 16px;
  padding: 0 10px;
  border-radius: 3px;
  background-color: #fff;
}
.btnBlue {
  background-color: #153c27 !important;
  color: #ffffff !important;
  padding: 6px 20px !important;
  // text-transform: uppercase !important;
  // border-radius: 0px !important;
  margin-right: 10px;
}
.btnLightBlue {
  background-color: #29abe2 !important;
  color: #ffffff !important;
  padding: 6px 20px !important;
  // text-transform: uppercase !important;
  // border-radius: 0px !important;
}
.textRight {
  text-align: right;
}
.btnPurple {
  background: #ff1c53 !important;
  color: #ffffff !important;
  // border-radius: 0px !important;
}
.cards {
  padding: 12px 10px;
  border-radius: 0px !important;
}
.black {
  color: #1d2939;
  font-weight: 600;
}
.gray {
  font-weight: 500;
  color: rgba(29, 41, 57, 0.7);
}
// Spinner

.spinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.relative {
  position: relative;
}
.deleteIcon {
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.tabActive {
  border-bottom: 2px solid #153c27;
  color: #153c27 !important;
}

.colorstyle {
  height: 20px;
  width: 20px;
  margin: 0 auto;
}
.removeButton {
  position: absolute;
  background-color: rgb(18, 18, 18);
  padding: 6px 10px;
  border-radius: 50% !important;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #fff;
  margin: -20px;
}
.disabledField {
  cursor: not-allowed;
}
