
  .counsellor-invoice .b-box{
    padding: 10px;
    width: 50%;
  }
  .flex{
    display: flex;
  }
  .align-center{
    align-items: center;
  }
  .lable{
    font-size: 10px;
    font-weight: 600;
    color: #000;
    margin-right: 10px;
  }
  .totalsection{
    width: 50%;
  }
  .total li {
  display: flex;
  justify-content: space-between;
  // border-bottom: 2px solid #AAB7B8;
  // background-color: white;
  padding: 5px;
  }
  .total li:last-child {
    border-bottom: none;
    }
  .total{
    padding: 0px;
  }
  .white{
    background-color: #fff;
  }
  .total .title{
    padding: 0 !important;
  }
  .nfc-location{
  padding: 10px;
  }
  .nfc-location th{
    background-color: rgba(80,165,241,.25)!important;
    color: #50A5F1;
    padding-left: 5px;
  }
  .nfc-location td{
    padding: 5px;
  }
  .nfc-location tr{
    border-bottom: 2px solid #50A5F1;
  }
  .nfc-location tr:last-child{
    border-bottom: none;
  }
  .nfc-location .lable{
    margin-bottom: 5px;
  }
  .misc{
    padding: 10px;
    }
    .Misc table{
      width: 100%;
    }
    .misc th{
      background-color: rgba(80,165,241,.25)!important;
      color: #50A5F1;
      padding: 5px;
    }
    .misc td{
      padding: 5px;
    }
    .misc tr{
      border-bottom: 2px solid #50A5F1;
    }
    .misc tr:last-child{
      border-bottom: none;
    }
    .misc .lable{
      margin-bottom: 5px;
    }
    .text-center{
      text-align: center;
    }
    .title{
      font-size: 10px;
    }
    .counsellor-invoice .Misc table{
  width: 100% !important;
}
.brand-section{
  display: flex;
  margin: 10px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.brand-section .brand{
  color: rgb(73, 80, 87);
    font-weight: 600;
    font-size: 1.5rem;
    margin-top: 10px;
    margin-bottom: 10px;
}
// top left side
.bill-to-section{
  display:flex !important;
  justify-content:center !important;
  font-size: 12px !important;
}
.bill-to-section .left{
  text-align:right;
  font-weight:600;
}
.bill-to-section .right{
  margin-left: 20px;
}
// top right side
.summary{
  display:flex;
  justify-content:center;
  font-size: 12px !important;
}
.summary .left{
  text-align:right;
  font-weight:600;
}
.summary .right{
  padding-left: 20px;
}
.summary-lable{
  font-size: 16px;
  font-weight:600;
}
.total{
  font-size: 14px;
  font-weight:600;
}
.summary .bottom{
  display: flex;
}
.template .lable{
  font-size: 16px;
}
.template table th{
  font-size: 14px;
  color: #000 !important;
}
.template table td{
  font-size: 12px;
}