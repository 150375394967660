.cardcenter {
  justify-content: center;
}

.addauct {
  text-align: right;
}

.circleinfo {
  margin-left: 3px;
  font-size: 15px;
  color: #cac5c5;
}

.subTitleStyle {
  font-size: 12px;
  color: #74788d8a;
}

.subTitleStyle a {
  text-decoration: underline !important;
}

.copyIcon {
  cursor: pointer;
}

.subtitlemenu {
  font-family: var(--bs-body-font-family);
  font-size: 14px !important;
  color: #000 !important;
  opacity: 0.5;
}

.userProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.socialLinks {
  display: flex !important;
  margin-top: 2.875rem;
  cursor: pointer;
}

.socialImg {
  height: 2.313rem;
  width: 2.313rem;
  border-radius: 50%;
  padding: 2px;
  background-color: #fff;
  margin-right: 1.5rem;
}

.card {
  height: 90%;
}

.headingsubtitle {
  margin-left: 12px;
  margin-top: 7px;
}

.paperworkSubmitted {
  height: 15px;
  width: 15px;
  background-color: #50a531;
  border-radius: 50%;
}

.expired {
  height: 15px;
  width: 15px;
  background-color: #f40707;
  border-radius: 50%;
}

.stylesquare {
  height: 15px;
  width: 15px;
  background-color: #5e82f8;
}

.twoWeekAndSession {
  height: 15px;
  width: 15px;
  background-color: #78797d;
}

.oneWeekAndSession {
  height: 15px;
  width: 15px;
  background-color: #edcb20;
}

// .unpaid {
//   width: 0;
//   height: 0;
//   border-left: 10px solid transparent;
//   border-right: 10px solid transparent;
//   border-bottom: 15px solid #f40707;
// }

.unpaid {
  position: relative;
  background-color: #f40707;
  text-align: left;
}

.unpaid:before,
.unpaid:after {
  content: '';
  position: absolute;
  background-color: inherit;
}

.unpaid,
.unpaid:before,
.unpaid:after {
  width: 10px;
  height: 10px;
  border-top-right-radius: 30%;
}

.unpaid {
  transform: rotate(-60deg) skewX(-31deg) scale(1, .866);
}

.unpaid:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, .707) translate(0, -50%);
}

.unpaid:after {
  transform: rotate(135deg) skewY(-45deg) scale(.707, 1.414) translate(50%);
}

// .partiallyPaid {
//   width: 0;
//   height: 0;
//   border-left: 10px solid transparent;
//   border-right: 10px solid transparent;
//   border-bottom: 15px solid orange;
// }

.partiallyPaid {
  position: relative;
  background-color: orange;
  text-align: left;
}

.partiallyPaid:before,
.partiallyPaid:after {
  content: '';
  position: absolute;
  background-color: inherit;
}

.partiallyPaid,
.partiallyPaid:before,
.partiallyPaid:after {
  width: 10px;
  height: 10px;
  border-top-right-radius: 30%;
}

.partiallyPaid {
  transform: rotate(-60deg) skewX(-31deg) scale(1, .866);
}

.partiallyPaid:before {
  transform: rotate(-135deg) skewX(-45deg) scale(1.414, .707) translate(0, -50%);
}

.partiallyPaid:after {
  transform: rotate(135deg) skewY(-45deg) scale(.707, 1.414) translate(50%);
}

.cardcenter {
  justify-content: center;
}

.addauct {
  text-align: right;
}

.circleinfo {
  margin-left: 3px;
  font-size: 15px;
  color: #cac5c5;
}

.subTitleStyle {
  font-size: 12px;
  color: #74788d8a;
}

.subTitleStyle a {
  text-decoration: underline !important;
}

.copyIcon {
  cursor: pointer;
}

.subtitlemenu {
  font-family: var(--bs-body-font-family);
  font-size: 14px !important;
  color: #000 !important;
  opacity: 0.5;
}

.userProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.socialLinks {
  display: flex !important;
  margin-top: 2.875rem;
  cursor: pointer;
}

.socialImg {
  height: 2.313rem;
  width: 2.313rem;
  border-radius: 50%;
  padding: 2px;
  background-color: #fff;
  margin-right: 1.5rem;
}

.label {
  //max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}

.card {
  height: 90%;
}

.headingsubtitle {
  margin-left: 12px;
  margin-top: 7px;
}

// blinking icon

@-webkit-keyframes blinker {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

@keyframes blinker {
  0% {
    opacity: 1.0;
  }

  50% {
    opacity: 0.0;
  }

  100% {
    opacity: 1.0;
  }
}

.bellContainer {
  // position: relative;
  // float: left;
  display: flex;
  text-align: center;
  justify-content: center;
}

// .bell {
//   // width: 100px;
//   // text-align: center;
//   // vertical-align: middle;
// }

.blink {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  height: 12px;
  width: 12px;
  background-color: rgb(247, 20, 20);
  border-radius: 50%;
}

.activeBlink {
  -webkit-animation: blinker 2s linear infinite;
  animation: blinker 2s linear infinite;
  // animation: 
}