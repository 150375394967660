
:root {
  --background-color:#F4F7FE!important;
  --text-color-dark: #000;
  --text-dark: #1e1e1e;
  --text-light: #ffffff;
  --text-color-inactive-links: #A3AED0;
  --dark-green: #22696D;
  --btn-disabled: #7FA8AA;
  --green-color-light: #84a17d;
  --green-color-light-table: #84a17d;
  --green-color-bg-card: #e2ecdf;
  --green-color-bg-card-tbl: #e2ecdf;
  --green-color-bg-card-th: #e2ecdf;
  --bs-body-font-size: 1rem;
  --bs-nav-link-color: var(--dark-green);
  --bs-nav-link-hover-color: var(var(--green-color-light));
  --bs-btn-color: #fff;
  --bs-btn-bg: #22696D;
  --bs-btn-border-color: #22696D;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #488386;
  --bs-btn-hover-border-color: #488386;
  --bs-btn-focus-shadow-rgb: 95, 106, 187;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #153c27;
  --bs-btn-active-border-color: #153c27;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #2e4338;
  --bs-btn-disabled-border-color: #2e4338;
  --bs-card-green-light: #cddbc9;
  --bs-card-green-light-tr: #cddbc9;

}

@font-face {
  font-family: 'Satoshi';
  src: url(../satoshi/Satoshi-Variable.ttf) format('truetype') ,
}

body {
  font-family: "DM Sans", sans-serif;
  background-color:var(--background-color)!important;

}
.account-pages {

  background-color:var(--background-color)!important;
  height: 100vh;
}
.accent-login-image {
  position: absolute;
  bottom: 5%;
  right: -10%;
}
.login-left-section div {
  text-align: center;
  margin: 0px auto;
  display: block;
  position: relative;
}
.authentication-bg {
  background: white !important;
}
.custom-card {
  border: 1px solid #E8E8E8 !important;
  background: white;
  border-radius: 8px;
  /* box-shadow: 0px 4px 4px 0px rgba(132, 161, 125, 0.1); */
  box-shadow: none!important;
}
.logo-center {
  text-align: center;
  width: 200px;
  margin-bottom: 20px;
}
.height-100vh {
  height: 100vh !important;
}
.h1-login-details,
.form-label-login {
  color: var(--text-color-dark);
  font-weight: 500;
}
.form-div-login-page input {
  background: white !important;
}
.buttons-div-login-page button {
  flex-basis: 50%;
  font-size: 16px;
  padding: 10px 14px;
}
.buttons-div-login-page button:first-child {
  border-radius: 8px 0px 0px 8px;
}
.buttons-div-login-page button:last-child {
  border-radius: 0px 8px 8px 0px;
}
.button-default-login-page {
  border: 1px solid var(--dark-green) !important;
}
.button-default-login-page,
.firebaseui-form-actions button {
  background: var(--dark-green)!important;
  /* background: var(--dark-green) !important; */
}
.button-default-login-page-inactive {
  background: white !important;
  color: var(--dark-green) !important;
  border: 1px solid #cccccc !important;
}
.margin-top-login-page {
  margin-top: 10% !important;
}

/* mobile */
.firebaseui-container {
  box-shadow: none !important;
  margin: 0px !important;
  width: 100%;
  max-width: 100% !important;
}
.firebaseui-card-header {
  padding: 20px 0px !important;
}
.firebaseui-card-header h1,
.firebaseui-card-content {
  padding: 0px !important;
}
.firebaseui-textfield {
  padding: 20px 0px !important;
}
.firebaseui-phone-number {
  border: 1px solid #ccc !important ;
  border-radius: 8px;
  padding: 0px 20px 0px 0px !important;
}
.firebaseui-country-selector {
  margin: 0px 24px 0px 0px !important;
  padding: 0px !important;
  border-bottom: none !important;
}
#rc-anchor-container {
  text-align: left !important;
}
.firebaseui-recaptcha-wrapper {
  margin: 0px !important;
  margin-top: 20px !important;
  margin-bottom: 10px !important;
}
.firebaseui-form-actions button {
  width: 100%;
  border-radius: 8px !important;
  margin: 0px !important;
  margin-bottom: 10px !important;
  display: block;
  color: white !important;
}
.firebaseui-card-actions {
  padding: 0px !important;
  width: 100% !important;
}

.bottom-cta-register-account {
  font-size: 16px;
  color: var(--text-color-dark);
  font-weight: 500;
  text-align: center;
  display: block;
  margin:0px;
}
.bottom-cta-register-account a {
  color: var(--dark-green);
  background: var(--background-color);
  border: 1px solid var(--dark-green);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap:10px;
  padding:8px 24px;
  border-radius:8px;
  
}
.create-account-link a{
  color: var(--dark-green);
}
.bottom-cta-register-account a:hover{
  background-color: var(--bs-btn-hover-bg);
  color: white !important;
}
.nav-tabs .nav-link.active {
  background-color: var(--dark-green) !important;
  color: white !important;
  border-color: transparent !important;
}
.show-password {
  float: right;
  position: relative;
  left: -15px;
  top: -30px;
  z-index: 2;
  cursor: pointer;
}
.enterOTPheading {
  font-size: 22px !important;
  font-weight: 600;
  color: #172426;
}
p.para-desc-login {
  font-size: 16px;
  font-weight: 500 !important;
  color: #495057;
}
.gap-devices-login-otp {
  gap: 10px;
}
.card-profile-selection {
  padding: 12px 20px;
  border-radius: 8px;
  border: 1px solid #cccccc;
}
.card-profile-selection h4 {
  font-weight: bold;
  font-size: 18px;
  color: #3f4e50;
  margin-bottom: 5px;
}
.card-profile-selection p {
  margin-bottom: 0px;
}
.swish-img-login {
  position: absolute;
  right: -80px;
  bottom: -40px;
  width: 150px;
  z-index: 0;
}
@media screen and (min-width: 2000px) {
  .login-left-section {
    min-height: 600px;
  }
}

@media screen and (max-width: 980px) {
  .login-left-section {
    height: 20%;
    max-height: 400px;
    min-height: 200px;
    padding: 0px;
    border-radius: 0px;
    margin-bottom: 2rem;
  }
  .login-left-section a {
    margin-bottom: 0px !important;
  }
  .accent-login-image {
    display: none;
  }
  .account-pages.login-account-pages {
    margin: 0px !important;
  }
}

/* mobile */
@media screen and (max-width: 600px) {
  .custom-card {
    padding: 10px !important;
  }
  .custom-card .w-75 {
    width: 100% !important;
  }
  .swish-img-login {
    display: none;
  }
}
@media screen and (max-width: 450px) {
  .logo-center {
    width: 200px
  }
}
@media screen and (max-width: 300px) {
  .form-div-login-page input {
    padding: 10px 0px;
  }
}
