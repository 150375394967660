.cardcenter {
    justify-content: center;
  }
  .addauct {
    text-align: right;
  }
  .circleinfo {
    margin-left: 3px;
    font-size: 17px;
    color: #cac5c5;
  }
  .subTitleStyle {
    font-size: 12px;
    color: #74788d8a;
  }
  .subTitleStyle a {
    text-decoration: underline !important;
  }
  .copyIcon {
    cursor: pointer;
  }
  .subtitlemenu {
    font-family: var(--bs-body-font-family);
    font-size: 14px !important;
    color: #000 !important;
    opacity: 0.5;
  }
  .userProfile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .socialLinks {
    display: flex !important;
    margin-top: 2.875rem;
    cursor: pointer;
  }
  .socialImg {
    height: 2.313rem;
    width: 2.313rem;
    border-radius: 50%;
    padding: 2px;
    background-color: #fff;
    margin-right: 1.5rem;
  }
  
  .card {
    height: 90%;
  }
  .headingsubtitle{
    margin-left: 12px;
    margin-top: 7px;
}

// Spinner 

.spinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}