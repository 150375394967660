@import url(../../assets/scss/styles.css);

.cardcenter {
  justify-content: center;
}

.addauct {
  text-align: right;
}

.circleinfo {
  margin-left: 3px;
  font-size: 15px;
  color: #cac5c5;
}

.subTitleStyle {
  font-size: 12px;
  color: #74788d8a;
}

.subTitleStyle a {
  text-decoration: underline !important;
}

.copyIcon {
  cursor: pointer;
}

.subtitlemenu {
  font-family: var(--bs-body-font-family);
  font-size: 14px !important;
  color: #000 !important;
  opacity: 0.5;
}

.userProfile {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.socialLinks {
  display: flex !important;
  margin-top: 2.875rem;
  cursor: pointer;
}

.socialImg {
  height: 2.313rem;
  width: 2.313rem;
  border-radius: 50%;
  padding: 2px;
  background-color: #fff;
  margin-right: 1.5rem;
}

.card {
  height: 90%;
}

.headingsubtitle {
  margin-left: 12px;
  margin-top: 7px;
}

.headerContainer {
  display: flex;
  align-items: center;
  gap: 3rem
}

.patientDetails {
  display: flex;
}

.patientDetailsInfo {
  flex: 50% !important;
}

.appointmentType {
  display: flex;
  gap: 0.5rem
}

@media only screen and (max-width: 600px) {
  .patientDetails {
    flex-direction: column;
  }

  .patientDetailsInfo {
    flex: 100% !important;
  }

  .appointmentType {
    gap: "5px"
  }
}

.calendarchip {
  display: block;
}

.calendarstyle {
  display: block;
}

.styleselectedCounselor {
  border-style: none;
}

.tabActive {
  background-color: #153c27 !important;
  color: #ffffff !important;
}

.todaystyle {
  background: var(--dark-green);
  color: #fff;
  border-radius: 50%;
  height: 23px;
  width: 25px;
  cursor: pointer;
}

// Spinner

.spinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.modalstyle {
  //position: relative;
  //flex: 1 1 auto;
  //padding: var(--bs-modal-padding);
  // max-height: 400px;
  // width: 483px;
  // overflow: auto;
}

.Buttonstyle {
  text-align: center;
  margin-top: 3px;
  margin-bottom: 12px;
}

.typebutton {
  width: 196px;
  height: 46px;
  border-radius: 50px;
}

.modalstyle {
  display: flex;
  justify-content: center;
}

.colorstyle {
  height: 20px;
  width: 20px;
}

.custom_radio_group{
  display: flex;
  gap: 8px;
}
.slotLabel {
  display: inline-block;
  padding: 8px 16px;
  background-color: #FFF;
  border: 2px solid #153C27;
  cursor: pointer;
  margin: 3px;
  border-radius:20px
}
.slotLabel:hover {
  background-color:  #153C27;
  color: #fff;
}
.slotLabel .active {
  background-color:  #153C27;
  color: #fff;
}
input[type="radio"]:checked + .slotLabel {
  background-color: #153C27;
  color:#fff
}
.radioactive{
  background-color: #153C27;
  color:#fff
}