
//
// Form-Upload
//

/* Dropzone */
.dropzone {
  min-height: 230px;
  border: 2px dashed $gray-400;
  background: $card-bg;
  border-radius: 6px;

  .dz-message {
    font-size: 24px;
    width: 100%;
  }

  .dz-message-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
  }

  .dz-message-info {
    height: 16px;
    font-weight: 400;
    font-size: 12.4px;
    line-height: 16px;
    color: #74788D !important;
  }
}