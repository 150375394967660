.removeButton {
  position: absolute;
  right: 0px;
  top: 10px;
  background-color: rgb(18, 18, 18);
  padding: 6px 10px;
  border-radius: 50% !important;
  border: 1px solid rgba(255, 255, 255, 0.18);
  color: #fff;
}
.warnText {
  color: #f46a6a;
  margin-top: .5rem;
}
