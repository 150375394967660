.typebutton {
  width: 110px;
  height: 40px;
  border-radius: 50px;
}
.joinBtn {
  border-radius: "70px";
  border: "none";
  width: "167px";
  background-color: "#29ABE2";
  display: "flex";
  justify-content: "center";
  align-items: "center";
  height: "58px";
  color: "#FFFFFF";
  &:focus {
    background-color: "#FFFFFF";
    color: "#29ABE2";
  };
  &:hover {
      background-color: "#FFFFFF";
      color: "#29ABE2";
  };
  &:active {
      background-color: "#FFFFFF";
      color: "#29ABE2";
  }
}
.joinBtnText {
  font-size: "17px";
  font-weight: 500
}
.warnText {
  color: #f46a6a;
  margin-top: .5rem;
}