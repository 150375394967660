// dashboard details
.no-show{
    list-style-type: none;
    padding: 10px !important;
    // margin: 10px !important;
}
.no-show li{
    box-shadow:rgb(149 157 165 / 10%) 0px 2px 4px;
}
.name{
    text-align: left;
    padding: 10px 5px;
    text-transform: capitalize;
}
.title{
    // text-align: center;
    font-weight: 600;
    font-size: 20px;
    padding: 0px 20px;
}
.createdDate{
    text-align: right;
    font-weight: 600;
    margin-top: 5px;
}