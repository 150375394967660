//
// _buttons.scss
//

button,
a {
    outline: none !important;
}

// Rounded Buttons
.btn-rounded {
    border-radius: 30px;
}

.btn-dark,
.btn-secondary {
    color: $gray-200;
}

.btn-outline-light {
    color: $gray-900;
}


.btn-group-vertical {
    label {
        margin-bottom: 0;
    }
}
