.container {
  margin-top: 40px;
  max-width: 505px;
  margin: 0 auto;

  .headImage {
    margin-top: 40px;
    margin-bottom: 10px;
  }

  h1 {
    font-family: "Proza Display";
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    margin-bottom: 20px;
  }

  h4 {
    font-family: "Inter";
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin-bottom: 15px;
  }
}

.backArrow {
  padding: 8px 15px;
}

.inputstyle {
  min-width: 245px;
  display: flex;
  border: 1px solid #d0d5dd;
  border-radius: 12px;
  padding: 13px 11px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;

  &:first-child {
    margin-right: 7.5px;
  }

  &:nth-child(2) {
    margin-left: 7.5px;
  }

  &:hover {
    cursor: pointer;
  }

  p {
    margin: 0px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #344054;
    text-transform: capitalize;
  }

  input {
    width: 20px;
    height: 20px;
    border: 1px solid #b1b1b1;
    border-radius: 4px;
    outline: none;
  }
}

.continueBtn {
  width: 100%;
  margin-bottom: 5px;
  color: #fff !important;
  background: #2e90fa !important;
  border: 1px solid #2e90fa !important;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 40px !important;
}

body .backToHomeBtn {
  background: #231f20;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #fff !important;
  border: 1px solid #231f20 !important;
  border-radius: 40px !important;
}

body .backToHomeBtn:hover {
  background: #fff;
  color: #231f20 !important;
  border: 1px solid #231f20 !important;
}

.ratingContainer>div {
  justify-content: flex-start;
}

.feedbackTextArea {
  width: 100%;
  height: 120px;
  padding: 5px;
  resize: none;
  margin-bottom: 15px;
  outline: none;
  background: #ffffff;
  border: 1px solid #d0d5dd;
  border-radius: 12px;
}

.submissionPara {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: #98a2b3;
}

.headingsubtitle {
  margin-left: 12px;
  margin-top: 7px;
}

.sessionContainer {
  display: grid;
  grid-template-columns: auto auto;
}