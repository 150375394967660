:root {
  --chart-text : #878e94
}
.main-content {
  overflow: unset !important;
}

.cp {
  cursor: pointer !important;
}

.phoneinpute {
  input {
    width: inherit !important;
    height: auto !important;
  }
}

// .offcanvas-end {
//   height: 32rem;
//   bottom: 20px;
//   top: auto;
//   right: 20px;
//   border-radius: 5px;
// }

.sub-title-menu {
  font-family: var(--bs-body-font-family);
  font-size: 14px !important;
  color: #000 !important;
  opacity: 0.5;
}

.chat-conversation-message {
  height: calc(100vh - 200px);

  @media (max-width: 991.98px) {
    height: calc(100vh - 190px);
  }
}

.pdf-box-red {
  background-color: #eb5757;
  border: 1px solid #eb5757;
  color: white;
  border-radius: 4px 0px 0px 4px;
  padding: 10px 6px;
}

.pdf-box-normal {
  border-radius: 0px 4px 4px 0px;
  border-left: 0px;
  width: 100%;
  color: #74788d;
  border-top: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
  border-bottom: 1px solid #eaeaea;
  align-items: flex-start;
  padding: 10px;
}

.dropzone-certificate {
  min-height: 68px;
}

.dropzonesmall {
  min-height: 38px;
  border: 1px solid #ced4da;
  background: #fff;
  border-radius: 4px;
  cursor: pointer;

  .dz-message {
    font-size: 24px;
    width: 100%;
  }
}

.edit-btn:hover,
.delete-btn:hover {
  text-decoration: underline !important;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end {
  margin-right: 2px;
  display: grid;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5em;
  padding: 6px 12px;
  margin-top: 10px;
}

.fc .fc-button-primary {
  background-color: #4350af;
  border: #4350af;
}

.fc .fc-toolbar-title {
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-transform: uppercase;
  color: #495057;
}

.fc-toolbar-chunk {
  display: flex;
  align-items: center;
}

.fc-toolbar-chunk {
  .fc-button-primary.fc-prev-button,
  .fc-button-primary.fc-next-button {
    background: none;
    border: 1px solid rgb(216, 210, 210);

    span {
      color: grey;
    }
  }
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  color: grey;
  background: none;
  border: 1px solid rgb(216, 210, 210);
}

.fc-timeGridWeek-button {
  color: grey;
  background: none;
  border: 1px solid rgb(216, 210, 210);
  cursor: pointer !important;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:first-child) {
  color: grey;
  background: none;
  border: 1px solid rgb(216, 210, 210);
}

.fc-theme-standard .fc-popover-header {
  background-color: #495057;
  color: white;
}

.fc .fc-col-header-cell-cushion {
  color: var(--text-color-dark);
}

.fc .fc-daygrid-day-number {
  color: var(--text-color-dark);
}

.fc {
  .fc-button {
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    color: #ffffff;
    padding-left: 14px;
    padding-right: 14px;
  }

  .fc-button::first-letter {
    text-transform: uppercase;
  }

  .fc-button-primary:disabled {
    background-color: #4350af;
  }

  .fc-button-active {
    background-color: #4350af !important;
    color: #ffffff !important;
  }

  .css-g1d714-ValueContainer {
    border: none;
  }
}

.fc-toolbar-chunk .fc-button-primary.fc-prev-button,
.fc-toolbar-chunk .fc-button-primary.fc-next-button {
  padding: 0px;
}

// appointmet calender css : start
.btn-cutom-danger {
  background-color: rgba(255, 100, 97, 0.05);
  color: rgba(255, 100, 97, 0.5);
  border-left: 2px solid rgba(255, 100, 97);
}

.btn-cutom-danger:hover {
  background-color: rgba(255, 100, 97);
  color: #fff;
}

.btn-custom-secondary {
  background-color: rgba(29, 41, 57, 0.05);
  color: rgba(29, 41, 57, 0.5);
  border-left: 2px solid rgba(29, 41, 57, 0.5);
}

.pr-custom-secondary,
.pr-custom-success,
.pr-custom-warning {
  color: #000;
}

.btn-custom-secondary:hover {
  background-color: rgba(29, 41, 57, 0.5);
  color: #fff;
}

.btn-custom-success {
  background-color: rgba(41, 171, 226, 0.05);
  color: rgba(41, 171, 226);
  border-left: 2px solid rgba(41, 171, 226);
}

.btn-custom-success:hover {
  background-color: rgba(41, 171, 226);
  color: #fff;
}

.btn-custom-warning {
  background-color: rgba(255, 166, 0, 0.05);
  color: rgba(255, 166, 0);
  border-left: 2px solid rgba(255, 166, 0);
}

.btn-cutom-danger {
  background-color: rgba(255, 100, 97, 0.15);
  color: rgb(255, 100, 97);
  border-left: 2px solid rgb(255, 100, 97);
}

.btn-custom-warning:hover {
  background-color: rgba(255, 166, 0);
  color: #fff;
}

.fc .fc-timegrid .fc-daygrid-body {
  display: none;
}

// appointmet calender css : end
.light-gray {
  color: var(--chart-text);
}

.fc-day-today {
  background: #fff !important;
  border: none !important;
}

// .fc .fc-scroller {
//   display: contents;
// }

.fc-day-today {
  .fc-daygrid-day-number {
    background-color: #4350af !important;
    color: #fff;
    border-radius: 50%;
    // padding: 3px 5px;
    height: 30px;
    width: 30px;
    text-align: center;
  }
}

.fc .fc-daygrid-day-frame {
  height: 100px;
}

.fc-h-event {
  background-color: transparent !important;
  border: 0px !important;
}

.fc-timegrid-event-harness {
  overflow: hidden;
}

.fc-timegrid-event-harness-inset .fc-timegrid-event {
  border-color: rgb(55 136 216) !important;
  min-height: 20px;
  cursor: pointer;
}

.fc .fc-popover {
  z-index: 9;
}

.fc .fc-timegrid-slot-minor {
  border-top: 0;
}

.fc-timegrid-event:has(.pr-custom-secondary) {
  background-color: #808080;

  border: none;
}

.fc-timegrid-event:has(.pr-cutom-danger) {
  background-color: #f55050;

  border: none;
}

.fc-timegrid-event:has(.pr-custom-warning) {
  background-color: #fbc252;

  border: none;
}

.fc-timegrid-event:has(.pr-custom-success) {
  background-color: #4350af;

  border: none;
}

.pr-custom-success {
  color: #fff;
  overflow: hidden;
  padding-left: 5px;
  cursor: pointer;
}

.pr-cutom-danger {
  color: #fff;
  overflow: hidden;
  padding-left: 5px;
  cursor: pointer;
}

.pr-custom-secondary {
  color: #fff;
  overflow: hidden;
  padding-left: 5px;
  cursor: pointer;
}

.pr-custom-warning {
  color: #fff;
  overflow: hidden;
  padding-left: 5px;
  cursor: pointer;
}

.fc .fc-button-group > .fc-button:focus {
  outline: none;
  box-shadow: none !important;
}

.error {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 87.5%;
  color: #f46a6a;
}

.spinner-container {
  height: "calc(100vh - 264px)" !important;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.data-table-wrap {
  // top:0;
  // left:0;
  opacity: 0.3;
}

.data-table-spinner {
  opacity: 1;
  z-index: 9999;
  position: absolute;
  top: 50%;
  left: 50%;
}

.sub-title-menu {
  font-family: var(--bs-body-font-family);
  font-size: 14px !important;
  color: #000 !important;
  opacity: 0.5;
}

.pointer {
  cursor: pointer;
}

.this-month .apex-charts text {
  font-weight: 600;
  font-family: "IBM Plex Sans";
  font-size: 11px;
  color: var(--chart-text);
}

.this-month .apexcharts-xaxis-texts-g text {
  font-weight: 600;
  font-family: "IBM Plex Sans";
  // font-size: 11px;
  color: var(--chart-text);
}

.least-performing th:first-child {
  white-space: nowrap;
}

// .apexcharts-yaxis-title-text {
//   display :none
// }

.blue-btn {
  background-color: #4350af !important;
  color: #ffffff !important;
  padding: 6px 20px !important;
  text-transform: uppercase !important;
  border-radius: 4px !important;
  margin-right: 20px;
}

.delete-icon {
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.apexcharts-yaxis-title {
  text {
    font-size: 14px !important;
  }
}
.red {
  color: red !important;
}
.btnLightBlue {
  background-color: #29abe2 !important;
  color: #ffffff !important;
  padding: 6px 20px !important;
}
.btnLightBlueDisable {
  background-color: #29abe2 !important;
  color: #ffffff !important;
  padding: 6px 20px !important;
  opacity: 0.5;
}
.on-board-backgrond {
  background-size: "cover";
  background-repeat: "no-repeat";
}
.invoice-card {
  height: 90px;
}
.link {
  color: #4350af;
  cursor: pointer;
}
.gridPreview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(47%, 1fr));
  grid-gap: 10px;
}
.w70 {
  width: 70%;
}
.loader-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // opacity: 0.5;
  z-index: 9999; /* Adjust z-index as needed */
}
.loader-overlayFix {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  // background-color: rgba(255,255,255,0.7);
  // background-color: rgba(0, 0, 0, 0.03); /* Overlay background color */
  display: flex;
  // align-items: center;
  // justify-content: center;
  // opacity: 0.5;
  z-index: 9999; /* Adjust z-index as needed */
}
.eclipes {
  overflow: hidden;
  width: 200px;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.commonTables th {
  font-size: 14px !important;
  font-family: "IBM Plex Sans" !important;
  font-weight: 600;
}
.commonTables td {
  font-size: 14px !important;
  font-family: "IBM Plex Sans" !important;
}
.redBoarder {
  border: 1px solid red;
}
.text-green {
  color: #172426;
}
.wieght600 {
  font-weight: 600;
}
.wieght700 {
  font-weight: 700;
}
.opacity5 {
  opacity: 0.5;
}
.please-wait {
  height: 100vh;
  font-weight: 600;
}
.wait-for-other-participents {
  text-align: center;
  z-index: 111;
  font-size: 14px;
  font-weight: 600;
}
.wait-for-other-participents span {
  color: #000;
  padding: 10px;
  position: absolute;
  z-index: 1111;
  background-color: rgba(255, 255, 255, 0.6); /* Adjust opacity and color */
  backdrop-filter: blur(10px); /* Same blur amount as the background */
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  top: 10px;
}
.vertical-align {
  vertical-align: top;
}

@media (min-width: 768px) {
  .gridPreview {
    grid-template-columns: repeat(2, 1fr 2fr);
  }
}
@media (max-width: 991px) {
  .react-bootstrap-table-pagination-list {
    margin-top: 5px !important;
    display: flex !important;
    justify-content: center !important;
  }
}
.ml-10 {
  margin-left: 10px;
}
.mr10 {
  margin-right: 10px;
}
.pl10 {
  padding: 10px;
}
.pr10 {
  padding: 10px;
}
.appointmentHight {
  height: calc(100vh - 200px);
  overflow-y: scroll;
}
.text-right {
  text-align: right;
}
.btn-secondary-ctm {
  border: none;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  color: "#fff";
  background-color: #74788d;
}
.fw600 {
  font-weight: 600;
}
.w50 {
  width: 50%;
}
.ml10 {
  margin-left: 10px;
}

@media (max-width: 600px) {
  /*video screen feedback button css*/
  .isStatusBar .bb-feedback-button {
    bottom: 90px;
  }
}
