.receipt-container {
    padding: 20px;
}

.nfc-logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 125px;
}
.invoice-title{
    margin-top: 10px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 1.5rem;
}

.nfc-logo-align {
    width: 250px;
    text-align: center;
}

.card-container {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    border: rgba(246, 246, 246);
    border-radius: 10px;
    padding: 20px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 15px;
    font-size: 10px;
}
tfoot td{
    color: rgba(73, 80, 87);
    font-weight: 600;
    font-size: 0px;
}

.receipt-card-title {
    color: rgba(73, 80, 87);
    font-weight: 600;
}

.total-section {
    margin-top: 20px;
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 15px;
    font-size: 1rem;
}

.total-item {
    background-color: rgba(243, 248, 251);
    box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
    border: rgba(246, 246, 246);
    border-radius: 5px;
    height: 100px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.total-title {
    color: rgb(73, 80, 87);
    margin: 0.25rem 0 0 0;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    white-space: nowrap;
}

.total-value {
    color: rgb(73, 80, 87);
    margin: 0.25rem 0 0 0;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    white-space: nowrap;
}

.appointment-table {
    margin-top: 10px;
    width: 100%;
}

.nfc-location {
    margin-top: 20px;
    padding: 10px;
}

.nfc-location th {
    background-color: rgba(80, 165, 241, .25) !important;
    color: #000 !important;
    padding-left: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 12px !important;
}
.blue{
    color: rgba(80, 165, 241, .25) !important;
    font-weight: 600;
}
.nfc-location td {
    padding: 5px;
    font-size: 10px !important;
}

.nfc-location tr {
    border-bottom: 2px solid #50A5F1;
}

.nfc-location tr:last-child {
    border-bottom: none;
}

.nfc-location .lable {
    margin-bottom: 5px;
    color: rgb(73, 80, 87);
    font-weight: 600;
    font-size: 1.5rem;
}

.misc {
    padding: 10px;
}

.misc table {
    width: 100%;
    border-left: 2px solid #50A5F1;
    border-right: 2px solid #50A5F1;
    border-bottom: 2px solid #50A5F1;
}

.misc th {
    background-color: rgba(80, 165, 241, .25) !important;
    color: #50A5F1;
    padding: 5px;
}

.misc td {
    padding: 5px;
}

.misc tr {
    border-bottom: 2px solid #50A5F1;
}

.misc tr:last-child {
    border-bottom: none;
}

.misc .lable {
    margin-bottom: 5px;
}

.text-center {
    text-align: center;
}
.nfc-location tfoot td{
    padding-top: 20px;
    margin-top: 20px;
}
.black{
    color: #000 !important;
}
.f14{
    font-size: 14px;
}
.bold{
    font-weight: 600;
}
.text-center{
    text-align: center;
}
.text-left{
    text-align: left;
}